<template>
  <div>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon>fas fa-file-invoice</v-icon>
        <v-toolbar-title class="pl-4">Notas não localizadas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="close">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Inicio filtros -->
        <v-row align="center">
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_start_date"
              v-model="modal.startDate"
              :return-value.sync="startDate"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatStartDateGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.startDate = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_start_date.save(startDate)">OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_end_date"
              v-model="modal.endDate"
              :return-value.sync="endDate"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatEndDateGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.endDate = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_end_date.save(endDate)">OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="1"
            style="max-width: 50px !important">
            <v-app-bar-nav-icon @click="loadItems()">
              <v-icon>fas fa-search</v-icon>
            </v-app-bar-nav-icon>
          </v-col>

          <v-col
            cols="3"
            class="px-6 py-6"
          >
            <v-text-field
              dense
              class="alinhamento"
              v-model="search"
              label="Pesquisar"
              type="text"
              autocomplete="off"
              clearable
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>


          <v-col
            cols="3"
            style="display: flex; justify-content: end;"
          >
            <v-btn
              color="success"
              @click="reprocess"
              v-if="this.selected.length > 0"
              >Reprocessar
            </v-btn>
            <v-btn
              color="success"
              @click="reprocess"
              v-else
              disabled
              >Reprocessar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Final filtros -->

        <v-row>
          <v-col class="px-3">
            <v-data-table
              dense
              :headers="grid.notas.cabecalho"
              :items="grid.notas.items"
              :items-per-page="grid.notas.perPage"
              v-model="selected"
              item-key="idProceda"
              show-select
              height="55vh"
              :search="search"
              fixed-header
              hide-default-footer class="elevation-1"
              :loading="grid.notas.loading"
            >
              <template #item.emissaoNf="{ item }"
                >{{ formatarDataBR(item.emissaoNf) }}</template
              >

              <template #item.custoFretePago="{ item }"
                >{{ item.custoFretePago >= 0 && item.custoFretePago != null
                      ? item.custoFretePago.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })
                      : "0,00" }}</template
              >

              <template #item.pesoPago="{ item }"
                >{{ item.pesoPago >= 0 && item.pesoPago != null
                      ? item.pesoPago.toLocaleString("pt-br", {
                          minimumFractionDigits: 3,
                        })
                      : "0,000" }}</template
              >

              <template #item.tipoConhecimento="{ item }">
                <span style="text-decoration: none">
                  {{ item.tipoConhecimento }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            style="display: flex; justify-content: end;"
          >
            <v-btn
              color="error"
              @click="deleteItem()"
              v-if="this.selected.length > 0"
              >Excluir
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem()"
              disabled
              v-else
              >Excluir
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal  -->

    <!-- Inicio Loader reprocessar -->
    <v-dialog v-model="loadingReprocess" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding-top: 7px">
          Reprocessando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader reprocessar -->

    <!-- Inicio Loader reprocessar -->
    <v-dialog
      v-model="modal.modalLogProcessedItems"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modal.modalLogProcessedItems = false, clearLogs(), loadItems()">
            Ok
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Log reprocessamento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in processedItems"
                    :key="item.message"
                  >
                    <td :style="!item.return ? 'color: red;' : 'color: green;'">{{ item.message }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader reprocessar -->
  </div>
</template>

<script>
import { baseApiUrlPersys, baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
  name: "NotasNaoLocalizadas",
  data: () => ({
    codigo_empresa: '',
    startDate: moment (new Date(new Date().getTime()).setDate(new Date().getDate() - 30)).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    search: "",
    loadingReprocess: false,
    selected: [],
    itensSelecionados: [],
    grid: {
      notas: {
        cabecalho: [
          {
            text: "Número Nota",
            value: "notaFiscal",
            sortable: true,
          },
          {
            text: "Serie NF",
            value: "serieNf",
            sortable: false,
          },
          {
            text: "Número dacte",
            value: "numeroDacte",
            sortable: false,
          },
          {
            text: "Data emissão",
            value: "emissaoNf",
            sortable: false,
          },
          {
            text: "Custo frete pago",
            value: "custoFretePago",
            sortable: false,
          },
          {
            text: "Peso pago",
            value: "pesoPago",
            sortable: false,
          },
          {
            text: "Tipo",
            value: "tipoConhecimento",
          },
          // {
          //   text: '',
          //   value: 'controls',
          //   sortable: false,
          // },
        ],
        items: [],
        bkpItems: [],
        loading: false,
        perPage: 10,
      },
    },
    modal: {
      data_inicio: false,
      data_final: false,
      modalLogProcessedItems: false,
    },
    processedItems: [],
    arrayAux: [],
  }),

  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  
  mounted() {
    this.loadItems();
  },

  computed: {
    formatStartDate() {
      return this.startDate
        ? moment(this.startDate).format("DD/MM/YYYY")
        : "";
    },
    formatEndDate() {
      return this.endDate
        ? moment(this.endDate).format("DD/MM/YYYY")
        : "";
    },
    formatStartDateGrid() {
      return this.startDate
        ? moment(this.startDate).format("DD/MM/YYYY")
        : "";
    },
    formatEndDateGrid() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    },
  },

  methods: {

    close () {
      this.$router.push("/");
    },

    async loadItems () {
      this.grid.notas.items = [];
      this.grid.notas.loading = true;
      await axios
        .get(`${baseApiUrlPersys}/log?fim=${this.endDate}&inicio=${this.startDate}`,  {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        // .get(`${baseApiUrlPersys}/log/${this.codigo_empresa}?fim=2022-11-30&inicio=2022-11-30`)
        .then((res) => {
          if(res.status == 200) {
            this.grid.notas.items = res.data;
            this.grid.notas.bkpItems = res.data;
            this.grid.notas.perPage = this.grid.notas.items.length;
            for (let i = 0; i < res.data.length; i++) {

              if(this.grid.notas.items[i].tipoConhecimento == "A"){
                this.grid.notas.items[i].tipoConhecimento = "Ajudantes"
              }else if(this.grid.notas.items[i].tipoConhecimento == "B"){
                this.grid.notas.items[i].tipoConhecimento = "Transbordo de carga"
              }else if(this.grid.notas.items[i].tipoConhecimento == "C"){
                this.grid.notas.items[i].tipoConhecimento = "Complementar"
              }else if(this.grid.notas.items[i].tipoConhecimento == "D"){
                this.grid.notas.items[i].tipoConhecimento = "Conhecimento de devolução"
              }else if(this.grid.notas.items[i].tipoConhecimento == "E"){
                this.grid.notas.items[i].tipoConhecimento = "Normal de entrada"
              }else if(this.grid.notas.items[i].tipoConhecimento == "F"){
                this.grid.notas.items[i].tipoConhecimento = "Frete de coleta"
              }else if(this.grid.notas.items[i].tipoConhecimento == "H"){
                this.grid.notas.items[i].tipoConhecimento = "Cobrança de stretch film"
              }else if(this.grid.notas.items[i].tipoConhecimento == "I"){
                this.grid.notas.items[i].tipoConhecimento = "Estadias"
              }else if(this.grid.notas.items[i].tipoConhecimento == "M"){
                this.grid.notas.items[i].tipoConhecimento = "Despesas com manuseio da carga"
              }else if(this.grid.notas.items[i].tipoConhecimento == "N"){
                this.grid.notas.items[i].tipoConhecimento = "Normal"
              }else if(this.grid.notas.items[i].tipoConhecimento == "O"){
                this.grid.notas.items[i].tipoConhecimento = "Normal  de retorno"
              }else if(this.grid.notas.items[i].tipoConhecimento == "P"){
                this.grid.notas.items[i].tipoConhecimento = "Transporte de pallets"
              }else if(this.grid.notas.items[i].tipoConhecimento == "R"){
                this.grid.notas.items[i].tipoConhecimento = "Conhecimento de reentrega"
              }else if(this.grid.notas.items[i].tipoConhecimento == "S"){
                this.grid.notas.items[i].tipoConhecimento = "Normal de saída"
              }else if(this.grid.notas.items[i].tipoConhecimento == "T"){
                this.grid.notas.items[i].tipoConhecimento = "Normal de transferência interna"
              }else if(this.grid.notas.items[i].tipoConhecimento == "U"){
                this.grid.notas.items[i].tipoConhecimento = "Custo de comunicação"
              }else if(this.grid.notas.items[i].tipoConhecimento == "W"){
                this.grid.notas.items[i].tipoConhecimento = "Complementar de retorno"
              }else if(this.grid.notas.items[i].tipoConhecimento == "X"){
                this.grid.notas.items[i].tipoConhecimento = "Complementar de entrada"
              }else if(this.grid.notas.items[i].tipoConhecimento == "Y"){
                this.grid.notas.items[i].tipoConhecimento = "Complementar de saída E"
              }else if(this.grid.notas.items[i].tipoConhecimento == "Z"){
                this.grid.notas.items[i].tipoConhecimento = "Complementar de transf. interna"
              }
            }
          }
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => (this.grid.notas.loading = false));
    },

    async reprocess () {
      this.itensSelecionados = [];
      this.loadingReprocess = true;
      for(let i = 0; i < this.selected.length; i++) {
        let aux = {
          cod_empresa: this.selected[i].codEmpresa,
          nota_fiscal: this.selected[i].notaFiscal,
          serie_nf: this.selected[i].serieNf,
        };
        // this.itensSelecionados.push(this.selected[i].idProceda)
        axios
          .post(`${baseApiUrl}/reprocess-proceda`, aux)
          .then((res) => {
            this.processedItems.push(
              {
                return: res.data.retorno,
                message: res.data.mensagem,
              }
            );
          })
          .finally(() => {
            this.loadingReprocess = false;
            this.selected = [];
            this.modal.modalLogProcessedItems = true;
          });
      }
    },

    async deleteItem () {
      this.itensSelecionados = [];
      for (let i = 0; i < this.selected.length; i++) {
        this.itensSelecionados.push(this.selected[i].idProceda)
      }

      this.$swal({
        title: 'Confirmação',
        text: `Confirma a exclusão da(s) nota(s) selecionada(s) ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .delete(`${baseApiUrlPersys}/log`,{
              data: {ids: this.itensSelecionados}
            })
            .then((res) => {
              this.$swal({
                title: 'Sucesso!!!',
                text: 'Notas excluídas com sucesso.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
              this.loadItems();
            })
            .catch((error) => {
              this.dialogError = true;
            })
            .finally(()=> {
              this.itensSelecionados = [];
              this.selected = [];
              console.log(this.itensSelecionados)
            })
        }
      });
    },

    formatarDataBR (value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },

    // async filter () {
    //   if (this.search == "") {
    //     this.loadItems();
    //   } else {
    //     this.grid.notas.items = this.grid.notas.bkpItems.filter(e => e.notaFiscal.indexOf(this.search) != -1);
    //   }
    // },

    clearLogs () {
      while(this.processedItems.length) {
        this.processedItems.pop();
      }
    }
  },
};
</script>

<style scoped>
.alinhamento {
  padding-bottom: 0 !important;
  padding-top: 25px !important;
}
</style>